@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-primary;
  }
}

@font-face {
  font-family: "fontprimary";
  src: url("/src/assets/font/CarbonaTest-Regular.woff");
}

@font-face {
  font-family: "fontbold";
  src: url("/src/assets/font/CarbonaTest-SemiBold.woff");
}
